import React, { useRef } from "react";

export default function NewsletterSignup() {
  const emailInputRef = useRef(null);

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent the default form submission to clear the input first
    // document.getElementById("mce-EMAIL").value = ""; // Clear the email input field

    event.currentTarget.submit(); // Proceed with the form submission
    emailInputRef.current.value = ""; // Clear the email input field
    emailInputRef.current.blur(); // Blur the input field
  };
  return (
    <>
      <section id="newsletter-text" className="pb-small">
        <p>
          <span className="fw-medium">Newsletter</span>
          <br />
          Receive updates on our products and projects.
        </p>
      </section>
      <section className="pb-large ">
        <form
          action="https://lighting.us9.list-manage.com/subscribe/post?u=0941c3b7c1732fd9de29735dc&amp;id=5ce85bc01c&amp;f_id=00e4c6e0f0"
          method="post"
          id="mc-embedded-subscribe-form"
          name="mc-embedded-subscribe-form"
          target="_blank"
          className="newsletter-form"
          onSubmit={handleSubmit}
        >
          <input
            type="email"
            name="EMAIL"
            id="mce-EMAIL"
            placeholder="Email Address"
            className="basic-text-input fs-mm-medium"
            ref={emailInputRef}
          />

          <button type="submit" className="capsule-link button-capsule-link">
            Subscribe
          </button>
        </form>
      </section>
    </>
  );
}
