import React from "react";
import { Helmet } from "react-helmet";
import NewsletterSignup from "../components/NewsletterSignup";

export default function newsletter() {
  return (
    <>
      <Helmet>
        <title>Subscribe to the Ratio Lighting Newsletter</title>
        <meta
          property="og:title"
          content="Subscribe to the Ratio Lighting Newsletter"
          key="ogtitle"
        />
        <meta
          property="og:description"
          content="Newsletter Subscription Form"
          key="ogdesc"
        />
        <meta name="description" content="Newsletter Subscription Form" />
      </Helmet>
      <main id="main">
        <div className="grid-content-footer px-medium pt-small fs-mm-xl">
          <article className="grid-child-content pb-large pb-mm-xl max-width-800">
            <NewsletterSignup />
          </article>
        </div>
      </main>
    </>
  );
}
